html {
  width: 100vw;
  height: 100vh;
}

body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  box-sizing: border-box;
  transition: 300ms transform;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: root 0ms forwards 300ms;
}

@keyframes root {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot {
  0% {
    padding-left: 0;
    padding-right: 0;
    border-radius: 50%;
  }
  25% {
    padding-right: 0;
    padding-left: 8px;
    border-radius: 25%;
  }
  50% {
    padding-right: 8px;
    padding-left: 8px;
  }
  75% {
    padding-right: 8px;
    padding-left: 0;
  }
  100% {
    padding-left: 0;
    padding-right: 0;
    border-radius: 50%;
  }
}
